

import './App.css';
import {scrollTo} from './helper/util';
import Header from './component/Header';
import Footer from './component/Footer';
import MainHero from './component/MainHero';
import AboutUs from './component/AboutUs';
import Service from './component/Service';
import Price from './component/Price';
import ContactUs from './component/ContactUs';

const HOME = "Home";
const ABOUTUS = "AboutUs";
const SERVICES = "Services";
const PRICING = "Pricing";
const CONTACTUS = "ContactUs";

const getHashLink = id => `/#${id}`;

const navs = [
  //{txt: "Home", link: getHashLink(HOME), id: HOME},
  {txt: "About us", link: getHashLink(ABOUTUS), id: ABOUTUS},
  {txt: "Services", link: getHashLink(SERVICES), id: SERVICES},
  {txt: "Pricing", link: getHashLink(PRICING), id: PRICING},
  {txt: "Contact us", link: getHashLink(CONTACTUS), id: CONTACTUS},
];

const handleAppointment = e => scrollTo(e.pageY, CONTACTUS); 

function App() {
  return (
    <div>
      <Header navs={navs} />
      <div id={HOME}>
        <MainHero onClickAppointment={handleAppointment} />
      </div>
      <div id={ABOUTUS} className='my-16'>
        <AboutUs />
      </div>
      <div id={SERVICES}>
        <Service />
      </div>
      <div id={PRICING}>
        <Price />
      </div>
      <div id={CONTACTUS} className='my-10'>
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}

export default App;
