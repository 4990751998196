import logo from '../image/logo.png';

const style = {
  root: {width: "117px"}
};

export default function Logo() {
  return (
    <img src={logo} alt="logo" style={style.root} />
  )
}
