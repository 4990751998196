import React from 'react';
import {motion} from 'framer-motion';

export default function MotionComponent({children, variants, ...otherProps}) {
  return (
    <motion.div variants={variants} initial="initial" animate="animate" exit="exit" {...otherProps}>
      {children}
    </motion.div>
  )
}
