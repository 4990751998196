import HeroImage from "./HeroImage";
import Center from './Center';
import Button from "./Button";
import MotionTopDown from "./MotionTopDown";
import TypeWriter from "./TypeWriter";

const style = {
  text1: {color: 'var(--sky)'}
};
export default function MainHero({onClickAppointment}) {
  return (
    <HeroImage imagePath="/image/top.jpg">
      <Center height="70vh">
        <div className="text-center">
          <div className="text-2xl sm:text-3xl font-bold mb-2" style={style.text1}>
            <TypeWriter text="Home or Office Looking Dull?" />
          </div>
          <MotionTopDown delay={2}>
            <div className="text-3xl sm:text-4xl md:text-5xl text-white font-bold mb-9">Professional Cleaning Service</div>
          </MotionTopDown>
          <MotionTopDown delay={3}>
            <Button className="clear text-xl" onClick={onClickAppointment}>Schedule an Appointment</Button>
          </MotionTopDown>
        </div>
      </Center>
    </HeroImage>
  )
}
