export const scrollTo = (initY, elementId, speed=80) => {
  const target = document.getElementById(elementId);
  let curY = initY;
  const scrollToInner = () => {
    curY += speed;
    if(curY < target.offsetTop){
      window.scrollTo(0, curY);
      setTimeout(scrollToInner, 10);
    } else {
      window.scrollTo(0, target.offsetTop);
    }
  }; 
  return scrollToInner();
};