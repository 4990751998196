import axios from 'axios';

export {
  sendMessage,
  getContactInfo
};

const URL = "api/contact";

function sendMessage(data){
  return axios.post(URL, data);
}

function getContactInfo(){
  return axios.get(URL);
}