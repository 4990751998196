
export default function HeroImage({imagePath, height="70vh", children}) {
  const style = {
    root: {
      position: 'relative',
      backgroundImage: `url('${imagePath}')`,
      height
    }
  };

  return (
    <div style={style.root} className="w-full bg-no-repeat bg-cover bg-center">
      {children}
    </div>
  )
}
