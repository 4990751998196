
import Row from "./Row";
import Container from './Container';

const style = {
  root: {
    background: "rgb(3, 38, 94)",
    color: "white"
  },
  content: {
    height: "135px",
  }
};
export default function Footer() {
  return (
    <div style={style.root}>
      <Container>
        <Row className="justify-center" style={style.content}>Copyright &copy; {(new Date()).getFullYear()} MK Cleaning. All rights reserved.</Row>
      </Container>
    </div>
  );
}
