import {keys} from 'ramda';

const style = {
  error: {color: 'red'}
};

export default function FormikField({id, name, label, formik, render, hideError, ...otherProps}) {
  return (
    // <div>
    //   {render({id, name, label, value: formik.values[name], onChange: formik.handleChange.bind(formik), onBlur: formik.handleBlur.bind(formik), ...otherProps})}
    //   {!hideError && <ErrorMessage formik={formik} name={name} />}
    // </div>
    <div className="mb-3">
      <div>
        <label htmlFor={id}>{label}</label>
      </div>
      {render({id, name, label, value: formik.values[name], onChange: formik.handleChange.bind(formik), onBlur: formik.handleBlur.bind(formik), ...otherProps})}
      {!hideError && <ErrorMessage formik={formik} name={name} />}
    </div>
  );
}

export function ErrorMessage ({formik, name}) {
  return formik.touched[name] && formik.errors[name] ? (<div style={style.error}>{formik.errors[name]}</div>) : null;
} 

export function ErrorSubmit({formik})
{
  return (
    <div className="text-center">
        {keys(formik.errors).length > 0 && <div style={style.error}>Please enter all required fields.</div>}
    </div>
  );
}
