import './TypeWriter.css';

const style = {
  wrapper: {
    display: "grid",
    placeItems: "center"
  }
};

export default function TypeWriter({text, duration=1.5}) {
  const steps = text.length - 2;
  const typingStyle = {
    width: `${steps}ch`,
    animation: `typing ${duration}s steps(${steps}), blink .5s step-end infinite alternate`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    borderRight: "3px solid"
  };

  return (
    <div style={style.wrapper}>
        <div style={typingStyle}>
          {text}
        </div>
    </div>
  )
}
