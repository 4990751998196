import classNames from "classnames";

const style = {
  image: {width: '220px'}
};
export default function ServiceCard({text, image, className}) {
  return (
    <div style={style.root} className={classNames("border-b border-b-3 border-blue-600", className)}>
      <div>{text}</div>
      <div><img src={image} alt={text} style={style.image} /></div>
    </div>
  )
}
