import classNames from "classnames";

const style = {
  root: {maxWidth: "1024px"}
};
export default function Container({children, className, ...props}) {
  return (
    <div className={classNames('container mx-auto px-9', className)} style={style.root} {...props}>{children}</div>
  )
}
