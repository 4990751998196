import {useState} from 'react';
import {scrollTo} from '../helper/util';
import Row from './Row';
import menuIcon from '../image/menu.svg';

const style = {
  icon: {width: '25px'},
  mobileMenu: {
    display: 'absolute',
    top: 0,
    left: 0
  }
};

export default function Nav({navs}) {
  const [showMobileMenu, setShowMobileMenu] = useState();
  const handleClick = nav => e => {
    scrollTo(e.pageY, nav.id);
    hideMobileMenul();
  }
  const toggleMobileMenu = () => setShowMobileMenu(prev => !prev);
  const hideMobileMenul = () => setShowMobileMenu(false);

  return (
    <>
      <Row className={"hidden xs:flex flex-wrap"}>
        <NavItems navs={navs} onClick={handleClick} />
      </Row>
      <div className="xs:hidden relative">
        <img src={menuIcon} alt="" style={style.icon} className="cursor-pointer" onClick={toggleMobileMenu} />
        <div className="absolute right-0 top-7 z-50 bg-white p-3 w-36">
          {showMobileMenu ? <NavItems navs={navs} onClick={handleClick} /> : null}
        </div>
      </div>
    </>
  )
}

function NavItems({navs, onClick}){
  return navs.map(d => <div><a key={d.txt} className='px-3 hover:text-sky-400' href={d.link} onClick={onClick(d)} >{d.txt}</a></div>);
}
