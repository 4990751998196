import Container from "./Container";
import HeroImage from "./HeroImage";
import Title from "./Title";

// const prices = [
//   "SM Rooms $70  up to 180 Sq/ft",
//   "Large Rooms $80  more than 180 Sq/ft",
//   "Stairs $50 up to 18 stairs ",
//   "Couch( Each seat) $70",
//   "Chair $20",
//   "Tile Sq/ft $1.30",
//   "Minimum Charge $175"
// ];

const style = {
  price: {maxWidth: '500px', width: "100%"}
};

const price = 
  "Cleaning prices are determined by square footage, number of rooms and soil level and house condition. If you need professional cleaning, please contact us for a quote.";

export default function Price() {
  return (
    <HeroImage imagePath={"/image/price.jpg"}>
      <Container className="pt-20 2xs:pt-36 md:pt-48">
        <Title className="justify-start">Price</Title>
        {/* <ul className="list-disc mt-2 2xs:mt-8 md:mt-14">
          {prices.map(p => <li key={p} className="font-bold text-lg">{p}</li>)}
        </ul> */}
        <div className="mt-2 2xs:mt-8 md:mt-14 text-lg md:text-xl font-bold w-96" style={style.price}>
          {price}
        </div>
      </Container>
    </HeroImage>
  )
}
