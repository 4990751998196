import Container from "./Container";
import Title from "./Title";
import ServiceCard from "./ServiceCard";
import Row from "./Row";

const style = {
  root: {background: "rgb(215, 242, 253)"}
};

const services = [
  {text: "", image: "image/picto1.jpg"},
  {text: "", image: "image/picto2n.jpg"},
  {text: "", image: "image/picto3n.jpg"},
  {text: "", image: "image/picto4n.jpg"},
  //{text: "", image: "image/picto5.jpg"},
];

export default function Service() {
  return (
    <div style={style.root} className="py-9">
      <Container>
        <Title>Service</Title>
        <Row className="flex-wrap justify-center gap-4">
        {
          services.map(s => <ServiceCard key={s.image} className="mb-3" {...s} />)
        }
        </Row>
      </Container>
    </div>
  )
}
