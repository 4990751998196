import {useFormik} from 'formik';
import * as Yup from 'yup';

import {sendMessage} from '../api/contact-api';
import FormikField from './FormikField';
import Input from './Input';
import Textarea from './Textarea';
import Button from './Button';
import Row from './Row';

const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: ""
};

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  phone: Yup.string(),
  message: Yup.string().required('Required'),
});

export default function ContactForm() {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit
  });

  function handleSubmit(values){
    sendMessage(values).then(() => {
      window.alert("Thank you. We have got your message.");
    }).catch(err => window.alert(err));
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormikField id="name" name="name" label="Name *" formik={formik} render={Input} />
        <FormikField id="email" name="email" label="Email *" formik={formik} render={Input} />
        <FormikField id="phone" name="phone" label="Phone" formik={formik} render={Input} />
        <FormikField id="message" name="message" label="Message *" formik={formik} render={Textarea} />
        <Row className="justify-center">
          <Button type="submit" className="default my-5">Submit</Button>
        </Row>
      </form>
    </div>
  )
}