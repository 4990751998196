import React from 'react';

import {getTopDownMotion} from '../helper/motion';
import MotionComponent from './MotionComponent';

export default function MotionTopDown({children, delay=0, duration=0.5, ...otherProps}) {
  const topDown = getTopDownMotion({animate: {transition: {duration, delay}}});
  return (
    <MotionComponent variants={topDown} {...otherProps}>
      {children}
    </MotionComponent>
  )
}
