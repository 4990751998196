import {useState, useEffect} from 'react';
import {getContactInfo} from '../api/contact-api';
import Container from "./Container";
import Title from "./Title";
import ContactForm from "./ContactForm";

export default function ContactUs() {
  const [contactInfo, setContactInfo] = useState();
  useEffect(() => {
    getContactInfo().then(res => setContactInfo(res.data));
  }, []);  

  return (
    <Container>
      <Title mb="mb-4">
        Contact us
      </Title>
      <div className="text-center">
        <p>
          Have questions? We’ve got answers.
        </p>
        <p>
          Phone: {contactInfo?.phone} / Email: {contactInfo?.email}
        </p>
      </div>
      <ContactForm />
    </Container>
  )
}
