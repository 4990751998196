import Container from "./Container";
import Title from "./Title";

export default function AboutUs() {
  return (
    <Container>
      <Title>About us</Title>
      <p className="mb-5">
        With over 22 years of experience in the cleaning industry our reputation has grown and grown.
        And we owe it all to you, our clients. Over the years we served ten's of thousands of happy, satisfied clients. 
        How do we do it? Simple, we value each and every client. We never give up on any stain and we train only 
        the best personnel.
      </p>
      <p>
        WE BELIEVE THAT A CLEAN HOME REFRESHES YOUR MIND, BODY & SPIRIT WHILE IMPROVING YOUR  OVERALL WELL-BEING.
      </p>
    </Container>
  )
}
