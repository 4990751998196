import Logo from "./Logo";
import Nav from "./Nav";
import Row from "./Row";
import Container from "./Container";

export default function Header({navs}) {
  return (
    <Container>
      <Row className="justify-between">
        <Logo /><Nav navs={navs} />      
      </Row>
    </Container>
  );
}
